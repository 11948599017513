<template>
  <div class="FQDNReferences">
    <h1>{{ $t('views.dnsvs.fqdn_records.records_to_fqdn') }} '<span v-if="$route.params.fqdn === '$'">.</span><span
        v-if="$route.params.fqdn !== '$'">{{ $route.params.fqdn }}</span>'
    </h1>
    <FlatRecordTable :full_edit_fqdn_reducer="full_edit_fqdn_reducer" :create_record_reducer="create_record_reducer"
                     :records="records" :fixed_fqdn="fqdn?fqdn.value:null" :fqdns="fqdns"
                     :subnets="subnets"></FlatRecordTable>
  </div>
</template>

<script>
import FlatRecordTable from '@/components/FlatRecordTable.vue'
import FQDNRRService from '@/api-services/dns_fqdn.service'

export default {
  name: 'FQDNReferences',
  data() {
    return {
      records: null,
      fqdn: null,
      fqdns: null,
      subnets: null,
      full_edit_fqdn_reducer: null,
      create_record_reducer: null
    }
  },
  methods: {
    async fetchData() {
      this.records = null
      const response = await FQDNRRService.getRecordsByFQDN(this.$store.state, this.$route.params.fqdn)
      if (response.data.fqdn_list.length === 0) {
        await this.$router.push('/404')
        return
      }
      this.subnets = response.data.ip_subnet_list
      const fqdnSelections = {}
      for (const t of response.data.fqdn_type_list) {
        let disp_name = t.description
        disp_name += ' ['
        disp_name += t.is_nonterminal ? this.$t('system.non_terminal') : this.$t('system.terminal')
        disp_name += ']'
        fqdnSelections[t.name] = {display_name: disp_name}
      }
      this.full_edit_fqdn_reducer = {
        type: fqdnSelections
      }
      const selections = {}
      for (const t of response.data.record_inttype_list) {
        selections[t.record_type] = {display_name: t.record_type}
      }
      const record_fqdn_type_reducer = Object.assign({'': {display_name: this.$t('system.automatic_handling')}}, this.full_edit_fqdn_reducer.type)
      this.create_record_reducer = {
        fqdn: false,
        type: selections,
        fqdn_type: record_fqdn_type_reducer
      }
      this.fqdn = response.data.fqdn_list[0]
      this.fqdns = {}
      this.fqdns[this.fqdn.value] = this.fqdn
      this.records = response.data.record_list
    }
  },
  components: {
    FlatRecordTable
  },
  watch: {
    $route() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
